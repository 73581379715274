import React from "react";

import { Helmet } from "react-helmet";

import './Work.css';

export const Work: React.FC = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Work With Us - Hallywlska Restaurang</title>
                <meta name="description" content="Join the team at Hallywlska Restaurang. Explore career opportunities with us." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/work-with-us" />
                <meta property="og:title" content="Work With Us - Hallywlska Restaurang" />
                <meta property="og:description" content="Join the team at Hallywlska Restaurang. Explore career opportunities with us." />
                <meta property="og:image" content="https://hallywlskarestaurang.com/work-with-us" />
                <meta name="twitter:title" content="Work With Us - Hallywlska Restaurang" />
                <meta name="twitter:description" content="Join the team at Hallywlska Restaurang. Explore career opportunities with us." />

            </Helmet>
            <section className="work beige-bg pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xl-4 offset-xl-2 d-flex justify-content-center order-md-1 order-2">
                            <img src="/img/work.jpg"
                                alt="Join the Hallwylska team"
                                className="mt-1 fly-left work__image"/>
                        </div>
                        <div className="col-md-6 col-xl-4 order-md-2 order-1 fly-right">
                            <h1 className="text-green">Work with us</h1>
                            <p>We're always on the lookout for talented individuals to join our team! If you're a chef, bartender, waiter, or runner, we'd love to hear from you</p>
                            <h4 className="work__subtitle">Send your CV to:</h4>
                            <p>
                                <a href="mailto:leemart@hallwylska.com">leemart@hallwylska.com</a>&nbsp;or&nbsp; 
                                <a href="mailto:julia@hallwylska.com">julia@hallwylska.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  };