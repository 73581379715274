import React from 'react';
import './App.css';
import { Navbar } from './components/navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { Footer } from './components/footer/Footer';
import { About } from './pages/about/About';
import { Event } from './pages/event/Event';
import { Team } from './pages/team/Team';
import { NotFound } from './pages/notfound/NotFound';
import { Menu } from './pages/menu/Menu';
import { Work } from './pages/work/Work';
// import { Drinks } from './pages/drinks/Drinks';
import { Calendar } from './pages/calendar/Calendar';
import { Julbord } from './pages/julbord/Julbord';
// import { Popup } from './components/popup/Popup';

function App(): JSX.Element {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/julbord" element={<Julbord />} />
            <Route path="/menu" element={<Menu />} />
            {/* <Route path="/drinks" element={<Drinks />} /> */}
            <Route path="/team" element={<Team />} />
            <Route path="/event" element={<Event />} />
            <Route path="/work" element={<Work />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        <Footer />
        {/* <Popup /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
